<template>
  <div id="app">
    <Header v-if="!$route.meta.isHidden"></Header>
    <router-view />
    <Footer v-if="!$route.meta.isHidden"></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header/index";
import Footer from "@/components/Footer/index";
import { mapActions } from "vuex";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      isHome: false,
    };
  },
  methods: {
    ...mapActions("types", ["getTypeList"]),
  },
  created() {
    this.getTypeList();
  },
};
</script>

<style lang="less">
@import url("@/style/iconfont/iconfont.css");
@mainWidth: 80vw;
@colorblue:rgb (65,161,224);
@colorWhite: #fff;
@font-face {
  font-family: "zkwyt"; //自行命名
  src: url("@/font/ZhanKuWenYiTi-2.ttf"); //导入下载好的字体
}
@font-face {
  font-family: "iconfont";
  src: url("@/style/iconfont/iconfont.ttf?t=1679388037571") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// body {
//   font-size: 0.05263vw;
// }
* {
  padding: 0;
  margin: 0;
}
ul {
  padding: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
}
</style>
