<template>
  <div>
    <div class="header-top">
      <div class="main flex">
        <div class="header-top-left">
          <p>多重安全检测下载网站、值得信赖的下载站！</p>
        </div>
        <div class="header-top-right">
          <ul class="flex">
            <li>
              <a href="/#/publish" class="btn">软件发布</a>
              <a-divider type="vertical" />
            </li>

            <li v-if="!userInfo">
              <a href="/#/login">登录</a>
              <a-divider type="vertical" />
            </li>

            <li v-if="!userInfo">
              <a href="/#/register">注册</a>
            </li>

            <li v-if="userInfo">
              <a href="/#/my">个人中心</a>
            </li>
            <li v-if="userInfo">
              <a href="javascript:void(0)" @click="logout">退出登录</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="header-mid">
      <div class="main flex">
        <div class="logo">
          <a href="/">
            <img v-if="system" :src="getUrl(system.system_logo)" alt="" />
          </a>
        </div>

        <ul class="flex">
          <li class="active">
            <a href="">首页</a>
          </li>

          <li v-for="item in typeList" :key="item.id">
            <router-link :to="'/type/' + item.id">{{
              item.type_name
            }}</router-link>
          </li>
        </ul>

        <form action="">
          <input type="text" placeholder="输入关键词搜索软件" />
          <a-icon type="search" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Header",

  data() {
    return {};
  },
  computed: {
    ...mapState("system", ["system"]),
    ...mapState("types", ["typeList"]),
    ...mapState("user", ["userInfo"]),
  },
  mounted() {},

  methods: {
    ...mapActions("user", ["logOut"]),
    async logout() {
      const data = await this.logOut();
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        return this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  padding: 2px 20px;
  display: inline-block;
  background-color: #5d96f7;
  border-radius: 30px;
  font-weight: 600;
}
.header-top {
  height: 45px;
  background-color: #000;
  .main {
    align-items: center;
    height: 100%;
    color: #e4e4e4;
    .header-top-left {
      width: 50%;
    }
    .header-top-right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      ul {
        li {
          // margin: 0 10px;
          /deep/ .ant-divider {
            margin: 0 10px;
          }
          a {
            color: #e4e4e4;
          }
        }
      }
    }
  }
}
.header-mid {
  width: 100%;
  height: 75px;
  background: #fff;
  line-height: 75px;
  .main {
    align-items: center;
    position: relative;
  }
  .logo {
    width: 180px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ul {
    margin-left: 100px;
    li {
      margin: 0 10px;
      a {
        display: block;
        color: #757575;
        font-weight: 600;
        transition: color 0.2s linear;
        font-size: 15px;
      }
      &:hover {
        a {
          color: #5d96f7;
        }
      }
    }
    li.active {
      a {
        color: #5d96f7;
      }
    }
  }
  form {
    height: 40px;
    width: 400px;
    border: 1px solid #efefef;
    border-radius: 30px;
    display: flex;
    align-content: center;
    position: absolute;
    right: 0;
    input {
      outline: 0;
      border: 0;
      width: 360px;
      height: 100%;
      padding-left: 20px;
      border-radius: 30px;
    }
    /deep/ .anticon {
      font-size: 20px;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>