import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './router.js';
import store from '@/store';
Vue.use(VueRouter);
import { reqGetSystem } from '@/api';
import { message } from 'ant-design-vue';
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
const whiteList = ['login', 'home', 'info', 'publish', 'type', 'article', 'company', 'companyIndex', 'search'];
router.beforeEach(async (to, _, next) => {
    const { token, userInfo } = store.state.user;
    const data = await reqGetSystem();
    if (data.code == 200) {
        document.title = data.data.system_name;
        store.state.system.system = data.data;
    }

    if (token) {
        if (to.name === 'login' || to.name === 'register') {
            message.info('已经登录,无需重复登录');
            try {
                next(_.fullPath);
            } catch (error) {
                console.log(error);
            }
        }
        if (userInfo) {
            next();
        } else {
            try {
                await store.dispatch('user/getUserInfo');
                next();
            } catch (e) {
                console.log(e.message);
                store.commit('user/resetUserInfo');
                next('/login');
            }
        }
    } else {
        if (whiteList.includes(to.name)) {
            next();
        } else next('/login');
    }
});
export default router;
