<template>
  <div class="footer">
    <div class="top" v-if="system">
      <div class="main">
        <div class="column">
          <p><a-icon type="phone" />联系电话</p>
          <ol>
            <li>联系电话：{{ system.system_phone }}</li>
          </ol>
        </div>
        <div class="column">
          <p><a-icon type="reconciliation" />联系邮箱</p>
          <ol>
            <li>电子邮箱:{{ system.system_email }}</li>
          </ol>
        </div>
        <div class="column">
          <p><a-icon type="bank" />公司地址</p>
          <h2>{{ system.system_address }}</h2>
        </div>
      </div>
    </div>

    <div class="bottom main">
      <p v-if="system">
        {{ system.system_copyright }}
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >备案号： {{ system.system_icp }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",

  data() {
    return {
      articles: [],
    };
  },
  computed: {
    ...mapState("system", ["system"]),
  },
  created() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.footer {
  background: #1b1f27;

  .top {
    width: 100%;
    min-width: 1200px;
    background: #20242e;
    height: 260px;
    .main {
      width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 60px 0 40px 0;
      box-sizing: border-box;
      p {
        font-size: 24px;
        color: #fff;
        .anticon {
          font-size: 42px;
          color: #fff;
          margin-right: 20px;
        }
      }
      h2 {
        color: #fff;
      }
      ol {
        all: unset;
        margin-top: 20px;
        list-style: none;
        li {
          font-family: MicrosoftYaHei;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
  .main {
    ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 30px;
      width: 80%;
    }

    li {
      h4 {
        font-size: 15px;
        font-weight: normal;
        color: #fff;
        margin-bottom: 20px;
      }
      p {
        margin: 0;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 168px;
        a {
          text-decoration: none;
          font-size: 12px;
          color: #adadad;
        }
      }
    }
    .qrcode {
      img {
        width: 94px;
        height: 94px;
      }
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
  }
  .bottom {
    font-size: 14px;
    padding: 10px 0;
    box-sizing: border-box;

    p {
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .address {
      color: #fff;
      span {
        margin-left: 50px;
      }
    }
  }
}
</style>